const comsList = [
  "Button",
  "DatePicker",
  "PickerGroup",
  "TimePicker",
  "Popup",
  "RadioGroup",
  "Radio",
  "Dialog",
  "Picker",
  "Pagination",
  "Icon",
  "Calendar",
  "BackTop",
  "NavBar",
  "Switch",
  "DropdownMenu",
  "DropdownItem",
  "PullRefresh",
];

import * as vantComs from "vant";
import "vant/lib/index.css";

const setupSmall = (app) => {
  comsList.forEach((item) => {
    app.use(vantComs[item]);
  });
};

export default setupSmall;
