<template>
  <el-select-v2
    :title="state.otherBank"
    v-model.trim="state.otherBank"
    placeholder="请选择"
    filterable
    remote
    :remote-method="remoteMethod"
    :loading="state.loading"
    :options="state.bankBranckList"
    style="width: 100%"
    :class="{ form_input_look: props.isLook }"
    :disabled="props.isLook"
    @change="onChange"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, reactive, watch, computed } from "vue";
import { onSelectFormat, globalThrottle } from "@/utils/date_tools";
import { bankBranckList as bankBranckListRequest } from "@/api/gas-station/public-api.js";

const emits = defineEmits(["update:modelValue"]);
const props: any = defineProps({
  modelValue: null,
  isLook: {
    type: Boolean,
    default: true,
  },
  mainBankName: {
    type: [String, Number],
    default: "",
  },
});

const state = reactive({
  otherBank: props.modelValue || "",
  bankBranckList: [],
  loading: false,
}) as any;

onMounted(async () => {
  init();
});

async function init() {
  remoteMethod("");
}

function onChange() {
  emits("update:modelValue", state.otherBank);
}

function remoteMethod(query: string) {
  state.loading = true;
  globalThrottle(async () => {
    apiRequest(query);
    state.loading = false;
  });
}

async function apiRequest(query) {
  if (!props.mainBankName) return;
  const res = await bankBranckListRequest(props.mainBankName, query);
  res.data = res.data || [];
  const bank_list = onSelectFormat(
    res.data,
    "branchBankName",
    "branchBankName"
  );
  const other = {
    label: "其他",
    value: "其他",
  };
  const bankList = [...bank_list, other];
  state.bankBranckList = bankList;
}

watch(
  () => props.mainBankName,
  () => {
    remoteMethod(state.otherBank);
  },
  {
    deep: true,
  }
);
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
